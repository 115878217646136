<template>
  <base-widget
    class="question"
    :type="theme"
  >
    <base-tag
      type="default"
      class="tag"
    >
      {{ category }}
    </base-tag>
    <h1 class="title">
      {{ title }}
    </h1>
    <base-button
      v-if="to"
      class="button"
      type="secondary"
      full-width
      :to="to"
    >
      Share your opinion!
    </base-button>
  </base-widget>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    theme() {
      return this.active ? 'dark' : 'light';
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  position: relative;
  flex-basis: 0;
  margin-bottom: 16px;
}

.tag {
  margin-bottom: 16px;
}

.title {
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  font-family: $font-family-title;
  line-height: 1.2em;
}

.button {
  margin-top: 32px;
}
</style>
