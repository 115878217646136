<template>
  <base-page
    class="home"
    :loading="isLoading && !user"
    centered="!hasPulses"
  >
    <template v-if="user">
      <!-- No pulses -->
      <base-graphic
        v-if="!hasPulses"
        :image="require('@/assets/images/graphic/waiting.svg')"
      >
        <template #title>
          Your dashboard
        </template>
        <template #body>
          Empty for now, but soon you will find new and previous questions here.
        </template>
      </base-graphic>

      <!-- Intake pulse -->
      <template v-else-if="hasIntakePulse">
        <base-title
          :level="1"
          class="title"
        />

        <widget-pulse
          title="Before you can participate in the weekly pulses we would like you to answer a few
                intake questions."
          category="Intake"
          :to="{ name: 'intake' }"
        />
      </template>

      <template v-else>
        <!-- No active pulses -->
        <base-graphic
          v-if="!hasActivePulses"
          :image="require('@/assets/images/graphic/playing.svg')"
          flipped
        >
          <template #title>
            You've answered all questions!
          </template>
          <template #body>
            You will receive a notification when we have a new question for you.
          </template>
        </base-graphic>

        <!-- Active pulses -->
        <template v-else>
          <base-title
            :level="1"
            class="title"
          >
            Hi {{ user.first_name }} 👋
          </base-title>

          <widget-pulse
            v-for="pulse in activePulses"
            :key="pulse.id"
            class="pulse--active"
            :title="pulse.question"
            :category="pulse.category"
            :to="{ name: 'pulse', query: { 'key': pulse.key } }"
          />
        </template>

        <!-- Inactive pulses -->
        <div
          v-if="hasInactivePulses"
          class="history"
        >
          <base-title
            :level="3"
            class="title title--history"
          >
            Previously answered
          </base-title>

          <widget-pulse
            v-for="pulse in inactivePulses"
            :key="pulse.id"
            class="pulse--history"
            :title="pulse.question"
            :category="pulse.category"
            :active="false"
          />
        </div>
      </template>
    </template>
  </base-page>
</template>

<script>
import PullToRefresh from 'pulltorefreshjs';

import WidgetPulse from '@/components/layout/WidgetPulse.vue';

export default {
  components: { WidgetPulse },
  data() {
    return {
      loading: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
    isUserLoading() {
      return this.$store.getters['user/isLoading'];
    },
    activePulses() {
      return this.$store.getters['activePulses/getActivePulses'];
    },
    isactivePulsesLoading() {
      return this.$store.getters['activePulses/isLoading'];
    },
    inactivePulses() {
      return this.$store.getters['inactivePulses/getInactivePulses'];
    },
    isInactivePulsesLoading() {
      return this.$store.getters['inactivePulses/isLoading'];
    },
    hasIntakePulse() {
      return !this.user.intake_answered;
    },
    hasActivePulses() {
      return this.activePulses && this.activePulses.length;
    },
    hasInactivePulses() {
      return this.inactivePulses && this.inactivePulses.length;
    },
    hasPulses() {
      return this.hasIntakePulse || this.hasActivePulses || this.hasInactivePulses;
    },
    isLoading() {
      return this.isUserLoading || this.isactivePulsesLoading || this.isInactivePulsesLoading;
    },
  },
  mounted() {
    PullToRefresh.init({
      mainElement: '.home',
      triggerElement: '.app',
      shouldPullToRefresh() {
        return !this.triggerElement.scrollTop;
      },
      onRefresh: () => Promise.all([
        this.$store.dispatch('user/fetchUser'),
        this.$store.dispatch('company/fetchCompany'),
        this.$store.dispatch('activePulses/fetchActivePulses'),
        this.$store.dispatch('inactivePulses/fetchInactivePulses'),
      ]),
    });
  },
  destroyed() {
    PullToRefresh.destroyAll();
  },
};
</script>

<style lang="scss" scoped>
.history {
  margin-top: 32px;
}

.title {
  margin-bottom: 16px;
}
</style>

<style lang="scss">
.ptr--ptr {
  box-shadow: none !important;
}
</style>
